/* Hash Box */
.hash-box {
  z-index: 1;
  position: fixed;
  left: 0;
  bottom: 0;

  color: white;
  background-color: rgba(0, 0, 0, 0.5);

  padding: 0.25rem;
  font-size: 0.75rem;
  line-height: 1;
}

/* SVG */
.build-tile {
  fill: black;
  opacity: 0.5;
}
.build-tile:hover {
  opacity: 1;
}

.token-contrast-road {
  stroke: rgba(0, 0, 0, 0.3333);
  stroke-width: 8;
  /* 0, 14, ${57.735 - 14 * 2}, 100 */
  stroke-dasharray: 0, 14, 29.735, 100;
}
.token-road,
.build-path {
  fill: none;
  stroke-width: 8;
  /* 0, 14, ${57.735 - 14 * 2}, 100 */
  stroke-dasharray: 0, 14, 29.735, 100;
}
.build-path {
  opacity: 0.5;
  stroke: black;
}
.build-path:hover {
  opacity: 1;
}

.build-intersection {
  opacity: 0.5;
  fill: black;
}
.build-intersection:hover {
  opacity: 1;
}

.token-contrast-settlement,
.token-contrast-city {
  stroke-width: 5;
  stroke: rgba(0, 0, 0, 0.3333);
  fill: none;
}
.token-settlement {
  stroke-width: 5;
  fill: rgba(255, 255, 255, 0.75) !important;
}
.token-city {
  stroke-width: 5;
}

.token-robber {
  fill: black;
}
.token-robber:hover {
  opacity: 0.75;
}

/* Pricing */
.pricing-group {
  display: inline-block;

  transform: translate(0, 2px);
}
.pricing-resource {
  display: inline-block;
  width: 1em;
  height: 1em;
  width: calc(1em - 2px);
  height: calc(1em - 2px);
  margin-right: -0.25em;
  border-radius: 0.5em;

  border: 1px solid rgba(0, 0, 0, 0.5);
}
.pricing-resource:last-child {
  margin-right: 0;
}

/* Hand */
.card-hand {}
.card-hand .btn {
  display: block;
  float: left;
  width: 5em;
  height: 5em;
  margin-right: -3.25em;
  padding: 0.25em 0 0 0;

  line-height: 1;

  transform: rotate(-90deg);
}
.card-hand.card-hand-lg .btn {
  width: 6em;
  height: 6em;
}
.card-hand .active {
  transform: rotate(-90deg) translate(0.75em, 0);
}

/* Customizations */
.btn {
  transition: none !important;
}
.btn.disabled,
.btn[disabled] {
  cursor: not-allowed;
}
.btn-colorized {
  border-color: rgba(0, 0, 0, 0.5);
}

.no-pointer {
  pointer-events: none;
}

/* Utils */
.text-decoration-line-through {
  text-decoration: line-through !important;
}

/* Colors */
@mixin resource-color ($color, $tags) {
  @each $tag in $tags {
    .fill-#{$tag} { fill: $color; }
    .stroke-#{$tag} { stroke: $color; }
    .bg-#{$tag} { background-color: $color; }
    .color-#{$tag} { color: $color; }
  }
}

@include resource-color(lightgray, mountains ore coin);
@include resource-color(lightsalmon, hills brick);
@include resource-color(wheat, fields grain);
@include resource-color(palegreen, pasture wool cloth);
@include resource-color(mediumseagreen, forest lumber paper);
@include resource-color(lemonchiffon, desert development);
@include resource-color(navy, unknown);

@mixin chit-color ($numbers, $color) {
  @each $number in $numbers {
    .fill-chit-#{$number} { fill: $color; }
    .stroke-chit-#{$number} { stroke: $color; }
  }
}

@include chit-color(2 3 4 5 9 10 11 12, black)
@include chit-color(6 8, firebrick)
